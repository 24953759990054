<template>
  <z-plate title="查看评分进度" has-bottom-border>
    <!-- 表格 -->
    <el-table class="z-table" :data="tableData" v-loading="tableLoading" style="width: 100%">
      <el-table-column type="index" label="序号" width="60"></el-table-column>

      <el-table-column prop="userName" label="评委姓名"></el-table-column>

      <el-table-column label="作品总量">
        <template slot-scope="{ row }">
          <span style="font-weight: bold; color: #333;">{{row.totalCount}}</span>
        </template>
      </el-table-column>

      <el-table-column label="已评数量">
        <template slot-scope="{ row }">
          <span style="font-weight: bold; color: #03a9f4">{{row.lockCount}}</span>
        </template>
      </el-table-column>

      <el-table-column label="未评数量">
        <template slot-scope="{ row }">
          <span v-if="row.unlockCount > 0" style="font-weight: bold; color: #f96a6a;cursor:pointer;" @click="goPage(row.userId)">{{row.unlockCount}}</span>
          <span v-else style="font-weight: bold; color: #f96a6a;">{{row.unlockCount}}</span>
        </template>
      </el-table-column>
    </el-table>
  </z-plate>
</template>

<script>
export default {
  data() {
    return {
    	activityid: sessionStorage.getItem("group_activityid"),
      tableData: [],
      tableLoading: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
  	goPage(userid){
  		sessionStorage.setItem("pingwei_userid",userid);
  		this.$router.push({name: 'AppraiseUnPingJiaVideo'})
  	},
    init() {
    	sessionStorage.removeItem("pingwei_userid");
      this.tableLoading = true;
      this.$post('/queryProgressByActivityid',{"activityid": this.activityid}).then(rep => {
      	this.tableLoading = false;
      	this.tableData = rep.content.tableData;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>